import React, { useState } from 'react';
import './style.scss';

import { Header } from '../../components/sections/Header/Header.jsx';
import { Footer } from '../../components/sections/Footer/Footer.jsx';
import { OfferSlider } from '../../components/sections/OfferSlider/OfferSlider.jsx';
import { BreadCrumbs } from '../../components/common/BreadCrumbs/BreadCrumbs';
import { TitleH1 } from '../../components/UI/TitleH1/TitleH1';
import { TitleH3 } from '../../components/UI/TitleH3/TitleH3';
import { TitleH4 } from '../../components/UI/TitleH4/TitleH4';
import { Date } from '../../components/common/Date/Date';
import { Text } from '../../components/UI/Text/Text';

import mapImg from './media/map.jpg';
import mapImg2 from './media/map2.jpg';

export const AboutPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <>
            <Header />
            <main className="main about-page">
                <div className="main-padding-horizontal about-page__bread-crumbs-wrapper">
                    <BreadCrumbs list={breadCrumbsList} />
                </div>
                <TitleH1
                    addClasses={['main-padding-horizontal', 'about__title']}
                >
                    О марафоне
                </TitleH1>
                {/* <section className="main-padding-horizontal about-page__section about-page__desc">
                    <ul className="about-tabs">
                        {tabs.map((item, index) => (
                            <li
                                onClick={() => setActiveTab(index)}
                                className={
                                    'about-tabs__item ' +
                                    (index === activeTab
                                        ? 'about-tabs__item_active'
                                        : undefined)
                                }
                            >
                                {item}
                            </li>
                        ))}
                    </ul>
                </section> */}
                {activeTab === 0 && (
                    <section className="main-padding-horizontal about-page__section">
                        {/* <TitleH3 addClasses={['about-page__title']}>
                            Зеленый Марафон в Уральском банке
                        </TitleH3>
                        <Text>
                            В этом году 5 июня Зеленый Марафон стартует в
                            рекордном количестве городов Уральского банка, его
                            площадками станут 22 муниципальных образования. В 9
                            городах:{' '}
                            <b>
                                Екатеринбурге, Уфе, Челябинске, Кургане,
                                Сургуте, Златоусте, Магнитогорске, Стерлитамаке,
                                Нефтекамске{' '}
                            </b>
                            Зеленый Марафон пройдет в оффлайн-формате, с
                            праздничными эко-акциями, развлекательными
                            программами и активностями от партнеров.
                            <br />
                            <br />
                            <br />
                        </Text>
                        <TitleH3 addClasses={['about-page__title']}>
                            Онлайн-забеги ЗМ также 5 июня состоятся в 13
                            городах.
                        </TitleH3>
                        <Text>
                            Это: Тюмень, Ханты-Мансийск, Салехард, Новый
                            Уренгой, Ноябрьск, Шадринск, Тобольск,
                            Нижневартовск, Нефтеюганск, Миасс, Нижний Тагил,
                            Каменск-Уральский, Серов. На их территории
                            участникам будет предложено скачать мобильное
                            приложение «Зеленый Марафон» и пробежать любую
                            дистанцию больше 1 км в своем городе. Каждый
                            километр будет приравнен к 1 рублю. Чем больше
                            километров пробегут участники в период с 10 до 17
                            часов, тем больше рублей будет направлено в общую
                            копилку – на посадку деревьев. Главная цель Зелёного
                            Марафона в этом году — привлечь внимание к проблемам
                            экологии и задуматься о том, как сказывается на
                            планете наше ежедневное потребление.{' '}
                            <b>
                                {' '}
                                Девиз проекта: «Каждый шаг — это след. Какой шаг
                                сделаешь ты?»
                            </b>
                        </Text> */}
                        <TitleH3 addClasses={['about-page__title']}>
                            Зеленый Марафон на Урале в этом году впервые прошел
                            в 22 городах
                        </TitleH3>
                        <Text>
                            Зеленый Марафон Сбербанка в этом году впервые прошел
                            в 22 городах Уральского федерального округа и
                            Республики Башкортостан. На старт вышли почти 12
                            тысяч человек, а посетителями спортивно-семейного
                            праздника стали свыше 20,5 тысяч жителей.
                            <br />
                            <br />В традиционном формате марафон прошел в
                            Екатеринбурге, Челябинске, Уфе, Кургане, Сургуте,
                            Магнитогорске, Стерлитамаке и Нефтекамске, а также в
                            Миассе и Златоусте, жители которых вышли на старт в
                            национальном парке «Таганай». Еще в 12 городах
                            впервые был опробован онлайн-формат забегов: это
                            Каменск-Уральский, Нижний Тагил, Серов, Тюмень,
                            Новый Уренгой, Ноябрьск, Салехард, Шадринск,
                            Тобольск, Нижневартовск, Нефтеюганск и
                            Ханты-Мансийск.
                            <br />
                            <br />В программе очных мероприятий — забеги на 1/10
                            марафонской дистанции в 4,2 км, детские забеги на
                            400 м для возрастных категорий 7-9 лет и 10-13 лет,
                            инклюзивные старты и соревновательный зачет в новой
                            дисциплине – скандинавской ходьбе на дистанции в 2
                            км. В онлайн-городах жители совершали индивидуальные
                            забеги с помощью трекера в мобильном приложении
                            «Зеленый Марафон» и получали в подарок экосувениры –
                            саше с семенами бархатцев.
                            <br />
                            <br />
                            Зеленый Марафон-2021 прошел под девизом «Каждый шаг
                            - это след. Какой шаг сделаешь ты?». Его главной
                            целью стала забота об экологии. Во всех городах
                            проведения марафона были организованы социальные и
                            экологические акции. Так, в Нижнем Тагиле, Серове и
                            Каменске-Уральском сотрудники Сбера в преддверии
                            Зеленого Марафона высадили голубые сибирские ели. В
                            Тюмени всем, кто посетил три финишных зоны, выдавали
                            экосумки, а рядом с центральным офисом Сбербанка
                            установили скамейки из переработанного пластика. В
                            Сургуте на точке сбора раздельного мусора было
                            собрано 34 кг пластиковых крышечек, 19,5 кг
                            использованных батареек, 22 кг пластика. В Уфе в
                            качестве социальной акции также была установлена
                            скамейка из переработанного пластика, который
                            собрали сотрудники Башкирского отделения Сбербанка.
                            <br />
                            <br />В Екатеринбурге 5 июня на территории
                            Центрального парка культуры и отдыха были высажены
                            12 уральских рябин. В экоакции приняли участие
                            председатель Уральского банка Сбербанка Дмитрий
                            Суховерхов, управляющий Свердловским отделением
                            Владислав Шиленко, заместитель регионального
                            министра физической культуры и спорта Александр
                            Маслов, заместитель главы Екатеринбурга Дмитрий
                            Ноженко, а также актер и сценарист шоу «Уральские
                            Пельмени» Андрей Рожков и олимпийские чемпионы –
                            Сергей Чепиков, Антон Шипулин, Олеся Красномовец,
                            Ольга Котлярова, Юрий Прилуков и Иван Алыпов. Всего
                            в рамках Зеленого Марафона на территории Уральского
                            банка Сбербанка было высажено более 30 тысяч
                            деревьев.
                            <br />
                            <br />
                            Одним из ярких событий на празднике в столице Урала
                            стал семейный экоквест, его участниками стали более
                            300 человек. На пяти точках-станциях квеста можно
                            было узнать свое давление и вес, обменять или
                            оставить книгу в столе буккроссинга, научиться
                            сортировать мусор, увидеть, с какими сложностями
                            сталкиваются во дворах водители мусоровозов,
                            прослушать интересные сообщения в эко-лектории.
                            Также на площадке марафона работали
                            благотворительные ярмарки, доход от которых был
                            направлен на помощь людям с инвалидностью.
                        </Text>
                    </section>
                )}
                {activeTab === 1 && (
                    <section className="main-padding-horizontal about-page__section">
                        <TitleH3 addClasses={['about-page__title']}>
                            Расписание фестиваля «ART-Пикник» и Зеленого
                            Марафона Сбербанка
                        </TitleH3>
                        <Text>
                            Привет, дорогой друг, и добро пожаловать на
                            долгожданное событие начала лета - Фестиваль досуга
                            и развлечений «АRT-ПИКНИК», который проводится
                            совместно с Зеленым Марафоном Сбербанка. Культурные
                            Центры Екатеринбурга подготовили для тебя яркую и
                            насыщенную программу, которая придется каждому по
                            душе!
                            <br />
                            <br />
                            <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            Главная сцена 09:00 – 20:00
                        </TitleH4>
                        <Text>
                            Утром с главной сцены будет дан старт забегам
                            «Зеленого Марафона» Сбербанка, пройдут
                            фитнес-разминки и награждения победителей забегов.
                            <br />
                            <br />
                            10:30 Торжественное открытие Зеленого Марафона{' '}
                            <br />
                            9:00 – 10:50 Регистрация на детские забеги <br />
                            9:00 – 12:00 Регистрация на основной забег 4,2 км. и
                            на северную (скандинавскую) ходьбу 2 км. <br />
                            10:45 Разминка перед детским забегом <br />
                            10:55 Построение детских забегов в возрастных
                            группах: 7 – 9 лет, 10 – 13 лет
                            <br />
                            11:00 Экологическая акция <br />
                            11:05 Детские забеги на 400 метров.
                            <br />
                            11:40 Награждение победителей детских забегов <br />
                            12:00 Разминка перед основным забегом и стартом
                            северной (скандинавской) ходьбы <br />
                            12:10 Основной забег на 4,2 км. <br />
                            12:15 Построение на старт северной ходьбы на 2 км.{' '}
                            <br />
                            12:20 Старт северной ходьбы <br />
                            13:00 Награждение победителей основного забега и
                            старта северной ходьбы <br />
                            10:00 – 13.45 Экоквест. 14:00 - Завершение
                            Экоквеста. Розыгрыш призов. <br />
                            <br />
                            С 14:00 на главной сцене выступят лучшие творческие
                            коллективы и исполнители культурно-досуговой сферы
                            города Екатеринбурга, пройдут разнообразные конкурсы
                            и розыгрыши.
                            <br />
                            <br />
                            <b> 14:00 Пикник с оркестром. В программе:</b>{' '}
                            <br />
                            Образцовый оркестр «Малахит» ЕДМШ №12 им. С.С.
                            Прокофьева и ЕДМШ №16 <br />
                            Камерный оркестр ДМШ№2 им. М.И. Глинки
                            <br />
                            Оркестр «Кудесы» ДМШ №7 им. С. Рахманинова
                            <br />
                            Камерный оркестр ДМШ №1 им. М. П. Фролова
                            <br />
                            Оркестр ДМШ №2 им. М.И. Глинки
                            <br />
                            Эстрадно-симфонический оркестр «Debut Simfo Jazz»
                            (ЕДМШ №12 им. С.С. Прокофьева). <br />
                            <br />
                            15:00 – 20:00 Выступления лучших творческих
                            коллективов и исполнителей культурно-досуговой сферы
                            города Екатеринбурга. <br />
                            18:45 Шоу-группа «Малина»
                            <br />
                            19:05 Бразис
                            <br />
                            19:30 Вокальный проект «Sample» <br /> <br /> <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            ДЕТИ / 09:00 – 20:00
                        </TitleH4>
                        <Text>
                            09:00 Показ мультфильмов <br />
                            12:00 Кукольный спектакль от Екатеринбургского
                            театра кукол <br />
                            12:45 Твистинг представление «ШараШоу» <br />
                            13:15 Детский мини-концерт от вокальной студии
                            «Чудеса» (руководитель Мартынова Марина Яковлевна){' '}
                            <br />
                            13:45 Интерактивные забавы от Театра игровых
                            программ «Разиния» <br />
                            15:00 Интерактивная программа «Солнечное лето» от
                            Театральной студии «На Варшавской» (руководитель
                            Аверьянов Денис Владимирович) <br />
                            16:30 Спектакль от детского театра «Алиса»
                            (художественный руководитель Кисенко Наталья
                            Викторовна) <br />
                            17:30 Твистинг представление «ШараШоу» <br />
                            18:00 Показ мультфильмов <br /> <br /> <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            РУССКАЯ ИЗБА / 09:00 – 20:00
                        </TitleH4>
                        <Text>
                            10:00 - 20:00 «Неразлучники», мастер-класс по
                            созданию традиционной куколки <br />
                            10:00 «Путешествие в старину», экскурсионная
                            программа <br />
                            12:00 «Русь танцевальная», игровая программа и
                            творческое выступление от детского фольклорного
                            ансамбля "Колокольчики". <br />
                            12:00 «Урало-сибирское волшебство», роспись ширмы{' '}
                            <br />
                            13:00 «Кот, петух и лиса», интерактивная программа
                            по русской народной сказке <br />
                            13:00 «Рисунки на спилах», мастер-класс по росписи,
                            уникальный сувенир из натурального дерева <br />
                            14:00 «Божья коровка», мастер-класс в технике
                            бумагоплатистика <br />
                            15:00 «Путешествие в старину», экскурсионная
                            программа <br />
                            15:00 «Матрёшка», мастер-класс по росписи по дереву{' '}
                            <br />
                            15:30 «Браслет счастья», мастер-класс по плетению
                            браслета из лент <br />
                            16:00 «Русь танцевальная», традиционные русские
                            народные игры и забавы <br />
                            16:00 «Арома мешочки», мастер-класс по озданию и
                            украшению ароматического мешочка саше <br />
                            16:30 «Птичка-невеличка», мастер-класс по созданию
                            подвески <br />
                            17:00 «В гости к печке»,
                            познавательно-развлекательная программа <br />
                            17:00 «Матрёшка», мастер-класс в технике оригами{' '}
                            <br />
                            18:00 «Кот, петух и лиса», интерактивная программа
                            по русской народной сказке <br />
                            18:40 «Путешествие в старину», экскурсионная
                            программа <br />
                            19:30 «Русь танцевальная», традиционные русские
                            народные игры и забавы <br />
                            <br />
                            <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            МОДА / 12:00 – 20:00
                        </TitleH4>
                        <Text>
                            12:00 Финал Регионального конкурса красоты и таланта
                            «Маленькая Мисс Европа&Азия» (продюсерский центр
                            «KARAMELLE») и показы известных детских брендов и
                            дизайнеров - FASHION NON STOP kids. <br />
                            17:00 Показ модных коллекций для взрослых.
                            Специальный гость площадки «Мода» известный
                            екатеринбургский дизайнер Лариса Одинцова. <br />{' '}
                            <br />
                            В течение всего дня на площадке «Мода» будут
                            работать стилисты-парикмахеры, которые проведут
                            мастер-классы по детской (мальчики и девочки),
                            мужской и свадебной прическе. Особенным сюрпризом
                            для зрителей станет возможность сделать праздничную
                            прическу на модном подиуме. Кроме того, все зрители
                            смогут поучаствовать в викторинах по истории моды и
                            получить приятные подарки от партнеров праздника.
                            <br />
                            <br />
                            <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            ТАНЦЫ / 10:00 - 20:00
                        </TitleH4>
                        <Text>
                            10:00 Интерактивная программа «Just Dance» <br />
                            10:30 Танцевальный флешмоб «Танцуй»,
                            Хореографический ансамбль «Мёд». <br />
                            11:00 Танцевально-развлекательная программа «Веселая
                            тусовочка» <br />
                            11:30 Интерактивная программа «Just Dance» <br />
                            12:00 Развлекательная программа «TikTok» от
                            Молодёжного театра «Игра» <br />
                            12:40 Мастер-класс по СТЕП-dance <br />
                            13:00 Анимационная программа «Супер вечеринка» от
                            Семейного центра «Почемучка» <br />
                            13:30 Мастер-класс по уличным танцам <br />
                            14:00 Танцевальный флешмоб «Ягода – Малинка» вместе
                            с Хореографическим ансамблем «Мёд» <br />
                            14:30 Интерактивная программа «Just Dance» <br />
                            15:00 Мастер-класс по бачате от Вадима Дягилева{' '}
                            <br />
                            15:30 Интерактивная программа «Just Dance» <br />
                            16:00 Мини-чемпионат от фестиваля DANCEKБ, номинации
                            Solo&Duets. <br />
                            18:00 Танцевальные баттлы «All Styles» <br /> <br />
                            <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            ОТДЫХ / 10:00 – 20:00
                        </TitleH4>
                        <Text>
                            Локация «Отдых» подойдет всем любителем чтения и
                            настольных игр. Здесь вы сможете посидеть на удобных
                            креслах-мешках, отдохнуть от тяжелых будничных дней,
                            насладиться свежим воздухом. Пока взрослые отдыхают,
                            самые маленькие гости фестиваля смогут поиграть в
                            развивающие бизиборды.
                            <br /> <br />
                            Мастер-классы для всех желающих научиться быстро
                            собирать кубик Рубика от педагогов и воспитанников
                            школы «Спидкубинга» - <b>13:00; 14:30; 16:30.</b>
                            <br />
                            <br />
                            <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            ИГРЫ / 10:00 – 20:00
                        </TitleH4>
                        <Text>
                            Локация создана специально для любителей интересных
                            и «эко-логичных» игр на сноровку, внимание и
                            ловкость. <br />
                            Вдохновляющее игровое пространство понравится и
                            маленьким и взрослым. Каждый раз, играя, мы находим
                            что-то новое, занимательное, побуждающее к
                            дальнейшему активному познанию. <br /> <br /> <br />
                        </Text>
                        <TitleH4 addClasses={['about-page__title']}>
                            РИСУНОК / 10:00 – 20:00
                        </TitleH4>
                        <Text>
                            Арт-площадка вдоль главной аллеи парка будет создана
                            специально в благодарность медицинским работникам,
                            которые, несмотря ни на что, помогали и продолжают
                            помогать нам бороться с вирусом. <br />
                            10 образов врачей СУПЕРГЕРОЕВ на 40 больших
                            мольбертах – раскрасках! <br />
                            Гости площадки с помощью маркеров и карандашей
                            создадут гигантские открытки, и таким образом
                            выразят признательность медикам. На площадке
                            разместятся два дерева с яблоками, плоды которых
                            будут предназначаться всем медицинским работникам.{' '}
                            <br />
                            <br />
                            Фестиваль «ART-Пикник» в ВКонтакте:{' '}
                            <a
                                target="_blank"
                                href="https://vk.com/artpiknikekb"
                            >
                                https://vk.com/artpiknikekb
                            </a>
                            <br />
                            Фестиваль «ART-Пикник» в Инстаграм:
                            <a
                                target="_blank"
                                href="https://www.instagram.com/artpiknikekb/"
                            >
                                https://www.instagram.com/artpiknikekb/
                            </a>
                            <br />
                            Официальный хэштег фестиваля #artpiknikekb, не
                            забудь поставить его в своих stories и под постами в
                            социальных сетях.
                            <br /> <br />
                            Узнать больше о культурно-досуговых центрах
                            Екатеринбурга –{' '}
                            <a
                                target="_blank"
                                href="http://xn--80atdujec4e.xn--80acgfbsl1azdqr.xn--p1ai/catalog/582/"
                            >
                                Центры культуры - список по Екатеринбургу -
                                Управление культуры Администрации Екатеринбурга
                            </a>
                        </Text>
                    </section>
                )}
                {activeTab === 2 && (
                    <section className="main-padding-horizontal about-page__section">
                        <img src={mapImg} alt="" />
                    </section>
                )}
                {activeTab === 3 && (
                    <section className="main-padding-horizontal about-page__section">
                        <Text>
                            Добро пожаловать на ЭКО-территорию. <br />
                            <br />
                            Каждый посетитель Зелёного марафона может
                            поучаствовать в эко-квесте. Пройди 5 эко-шагов,
                            получи 5 отметок печатей за выполнение заданий и
                            участвуй в розыгрыше призов на главной сцене 5 июня
                            в 14.00. <br /> <br />
                            Время проведения: 10:00 — 14:00
                            <br /> <br />
                            <br />
                        </Text>
                        <TitleH4>Первый шаг — НАЧНИ С СЕБЯ.</TitleH4>
                        <Text>
                            Измерь рост, вес, пульс, давление в зоне
                            СберЗдоровье. <br /> <br />
                        </Text>
                        <TitleH4>Второй шаг — ИЗУЧАЙ.</TitleH4>
                        <Text>
                            Чтобы понять и эффективно решить проблему, нужно её
                            изучить. Для этого необходимо пройти Эковикторину в
                            зоне Сбор книг. <br />
                            <br />
                        </Text>
                        <TitleH4>Шаг три — СОКРАЩАЙ</TitleH4>
                        <Text>
                            Узнай о правилах потребления в зоне ЭкоЛектория.{' '}
                            <br /> <br />
                        </Text>
                        <TitleH4>Шаг четыре — ПЕРЕРАБАТЫВАЙ</TitleH4>
                        <Text>
                            Научись правильно разделять мусор на Детской
                            площадке парка. <br /> <br />
                        </Text>
                        <TitleH4>Шаг пять — ИСПОЛЬЗУЙ ПОВТОРНО. </TitleH4>
                        <Text>
                            Посети #немузеймусора и узнай какие вещи могут
                            использоваться повторно. <br />
                            <br />
                        </Text>
                        <img src={mapImg2} alt="" />
                    </section>
                )}
                {activeTab === 4 && (
                    <section className="main-padding-horizontal about-page__section">
                        <Text>
                            10:00-10:15 Лекция "Вызовы нашего времени. Что такое
                            ESG?" Сбербанк <br /> <br />
                            10:15-11:00 Лекция и мастер-класс "Экологические
                            привычки" ВОО волонтеры-экологи "Делай" <br />{' '}
                            <br />
                            11:00-11:30 Лекция "Жизнь мусора" #немузеймусора{' '}
                            <br /> <br />
                            11:30-12:00 Лекция "Мусорная корзина в переработке"
                            #немузеймусора <br /> <br />
                            12:00-12:30 Лекция "От масс-маркета до локального
                            бренда" #немузеймусора <br /> <br />
                            12:30-13:15 Лекция "Волонтерство в рамках Всемирного
                            саммита спорта и бизнеса "СпортАккорд" 2021 и
                            Всемирных студенческих игр 2023" УРФУ <br /> <br />
                            13:15-14:15 Лекция и мастер-класс "Люди с
                            ментальными особенностями: мифы и предупреждения"
                            ДИСПО-центр <br /> <br />
                            14:15-15:00 Лекция "ЗОЖ. Миф или реальность" I Love
                            Supersport <br /> <br /> <br />
                            Про волонтерские и социальные проекты в Сбере
                            (Екатеринбург) узнавайте по ссылке{' '}
                            <a
                                target="_blank"
                                href="https://www.sbidea.ru/volunteer_sdelano_na_urale"
                            >
                                https://www.sbidea.ru/volunteer_sdelano_na_urale
                            </a>{' '}
                            Подключайтесь к нашим активностям!
                        </Text>
                    </section>
                )}
            </main>
            <Footer />
        </>
    );
};
const breadCrumbsList = [
    {
        title: 'Главня',
        link: '/',
    },
    {
        title: 'О марафоне',
        link: '/about',
    },
];
const tabs = [
    'О марафоне',
    // 'Тайминг Екатеринбург',
    // 'Карта мероприятия Екатеринбург',
    // 'ЭкоКвест Екатеринбург',
    // 'ЭкоЛекторий Екатеринбург',
];
